import React from 'react'
import { graphql } from 'gatsby'
import classNames from 'classnames'

// Components
import Layout from 'components/layout'
import SEO from 'components/seo'

// Styling
import './job-ad.scss'

// Types
import { Job } from 'types/teamtailor'

type Props = {
  data: {
    page: Job
  }
  pageContext: {
    slug: string
  }
}

type CreateMarkup = {
  __html: string
}

const createMarkup = (content: string): CreateMarkup => {
  return { __html: content }
}

const JobAdPageTemplate: React.FC<Props> = ({ data }) => {
  const { page } = data

  const body = createMarkup(page.attributes.body)

  const classes = classNames('jobAd-article standard-article', {
    'has-picture': page.attributes.picture !== null,
  })

  return (
    <Layout theme="Dark">
      <SEO
        title={page.attributes.title}
        description={page.attributes.pitch || ''}
      />
      <article className={classes}>
        {page.attributes.picture && (
          <div className={'jobAd-article__media'}>
            <img
              src={page.attributes.picture?.original}
              alt={page.attributes.title}
            />
          </div>
        )}
        <div className={'jobAd-article__content'}>
          <h1 className="jobAd-article__title">{page.attributes.title}</h1>
          <div
            className="jobAd-article__text"
            dangerouslySetInnerHTML={body}
          ></div>
        </div>
        <div className={'jobAd-article__apply-iframe'}>
          <iframe
            src={page.links.careersite_job_apply_iframe_url}
            width="100%"
            height="800px"
            frameBorder="0"
          />
        </div>
      </article>
    </Layout>
  )
}

export default JobAdPageTemplate

export const query = graphql`
  query SingleJobAd($slug: String!) {
    page: teamTailorJob(slug: { eq: $slug }) {
      attributes {
        title
        body
        picture {
          original
          standard
        }
      }
      links {
        careersite_job_apply_iframe_url
      }
    }
  }
`
